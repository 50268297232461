// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxLoggerLevel } from 'ngx-logger';
import { KeycloakAuthService } from 'src/app/auth/services/keycloak-auth.service';

export const environment = {
  production: false,
  level: NgxLoggerLevel.INFO,
  serverLogLevel: NgxLoggerLevel.INFO,
  disableConsoleLogging: true,
  applicationName: 'Sirius - Usine de Migration',
  applicationTitle: 'Sirius - Usine de Migration',
  applicationVersion: '1.0.0',
  // Global region for AWS Amplify configuration
  globalRegion: 'eu-west-3',

  // Identity region to Ireland
  identityRegion: 'eu-west-3',

  // API region for URL signing
  apiRegion: 'eu-west-3',
  apiKey: '',
  // PROD identifiers (allows only s3 origin)

  identityPoolId: 'eu-west-3:84954d1e-7b50-42bd-b79d-574cec383933',
  apiEndpoint: 'https://usine2mig-api-hom.dir.istefr.fr/',
  auth: {
    config: {
      config: {
        url: 'https://keycloak-hom.dir.istefr.fr/',
        realm: 'vef',
        clientId: 'USINE2MIG',
      },
      initOptions: {
        onLoad: 'check-sso',
        flow: 'standard',
        'enable-cors': true,
        pkceMethod: 'S256',
        silentCheckSsoRedirectUri:
          'https://usine2mig-hom.dir.istefr.fr/assets/silent-check-sso.html',
        'public-client': true,
      },
    },
    loginRedirectUri: 'https://usine2mig-hom.dir.istefr.fr/',
    logoutRedirectUri: 'https://usine2mig-hom.dir.istefr.fr/',
    provider: KeycloakAuthService,
    federatedSignInProvider: 'keycloak-hom.dir.istefr.fr/realms/vef',
  },
};
