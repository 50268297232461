import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { AppErrorComponent } from "./app.error/app.error.component";
import { AppNotfoundComponent } from "./app.notfound/app.notfound.component";
import { AppUnauthorizedComponent } from "./app.unauthorized/app.unauthorized.component";
import { AppHeaderComponent } from "./app.header/app.header.component";
import { AppMainComponent } from "./app.main/app.main.component";
import { AppSidebarComponent } from "./app.sidebar/app.sidebar.component";
import { AppNavbarComponent } from "./app.navbar/app.navbar.component";
import { AppMenuComponent } from "./app.menu/app.menu.component";
import { AppMenuitemComponent } from "./app.menu/app.menuitem.component";
import { ToastModule } from "primeng/toast";

@NgModule({
  declarations: [
    AppHeaderComponent,
    AppSidebarComponent,
    AppNavbarComponent,
    AppErrorComponent,
    AppNotfoundComponent,
    AppUnauthorizedComponent,
    AppMainComponent,
    AppMenuComponent,
    AppMenuitemComponent,
  ],
  imports: [SharedModule, ToastModule],
  exports: [],
})
export class AppLayoutModule {}
