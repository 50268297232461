import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  from,
  Observable,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from "rxjs";
import {
  NotificationsService,
  Severities,
} from "../services/notifications.service";
import { ExceptionResponseModel } from "../models/exception-response";
import { KeycloakAuthService } from "src/app/auth/services/keycloak-auth.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private router: Router,
    private keycloakAuthService: KeycloakAuthService,
    private notificationsService: NotificationsService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let clonedRequest = req.clone({ withCredentials: true });
    const userToken = this.keycloakAuthService.getUserToken;
    if (userToken) {
      clonedRequest = this.addTokenHeader(clonedRequest, userToken);
    }

    return next.handle(clonedRequest).pipe(
      tap((ret) => this.handleHttpReponse(ret)),
      catchError((error) => {
        if (error.status === 401 && this.keycloakAuthService.isTokenExpired) {
          return this.handle401Error(clonedRequest, next);
        }

        return this.handleError(error);
      })
    );
  }

  private addTokenHeader(
    req: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return req.clone({
      headers: req.headers.set("X-usine2mig-token", token),
    });
  }

  // gérer les réponse envoyé par le serveur
  private handleHttpReponse(httpEvent: HttpEvent<any>) {
    if (httpEvent.type == HttpEventType.Response) {
      let exceptionResponseModel: ExceptionResponseModel = httpEvent.body;
      if (
        ![201, 202, 204].includes(httpEvent.status) &&
        ![200, 201, 202, 204].includes(exceptionResponseModel.status) &&
        exceptionResponseModel != null
      ) {
        throw new HttpErrorResponse({
          error: exceptionResponseModel,
        });
      }
    }
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.keycloakAuthService.refreshToken().pipe(
        switchMap((newToken: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(newToken);
          return next.handle(this.addTokenHeader(request, newToken));
        }),
        catchError((error) => {
          this.isRefreshing = false;
          this.keycloakAuthService.logout();
          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshing = false;
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => next.handle(this.addTokenHeader(request, token)))
      );
    }
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === HttpStatusCode.Unauthorized) {
      this.router.navigate(["/not-authorized"]);
      return of();
    }

    if (err.status === HttpStatusCode.InternalServerError) {
      this.notificationsService.notify(
        Severities.ERROR,
        "Erreur",
        "Une exception non gérée s’est produite dans votre application"
      );
    } else {
      console.log(err);
      this.notificationsService.notify(
        Severities.ERROR,
        "Erreur",
        err.error?.message
      );
      throw Error(err.error?.message);
    }
    throw err;
  }
}
