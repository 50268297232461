import { Component, Input } from "@angular/core";

@Component({
  selector: "app-circular-icon",
  templateUrl: "./app-circular-icon.component.html",
})
export class CircularIconComponent {
  @Input() label: string;
  @Input() colorBorder: string;
  @Input() colorText: string;
}
