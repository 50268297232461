<p-avatar
  [label]="label"
  styleClass="custom-circular-avatar-sm rotating"
  [style]="{
    'background-color': 'transparent',
    color: colorText,
    border: '2px solid ' + colorBorder
  }"
>
</p-avatar>
