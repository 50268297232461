import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LayoutService } from 'src/app/core/services/app.layout.service';
import { ContexteUtilisateurService } from 'src/app/core/services/contexte-utilisateur.service';
import { Router } from '@angular/router';
import { UtilisateurApiService } from 'src/app/core/api-services/utilisateur-api.service';
import { DroitElementaireEnum } from 'src/app/shared/enums/DroitElementaireEnum';

@Component({
  selector: 'app-header',
  templateUrl: './app.header.component.html',
  styleUrls: ['./app.header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  projetDescription: string;
  projetEspace: string;
  emailSimulatedUser: string;
  hasEmailSimulatedUser: boolean = false;
  codeProjet: string;

  droitElementaireEnum=DroitElementaireEnum;
  
  constructor(
    private contexteUtilisateurService: ContexteUtilisateurService,
    private utilisateurApiService: UtilisateurApiService,
    public layoutService: LayoutService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.contexteUtilisateurService.contexteUtilisateurSubject.subscribe(
      (_) => {
        if (this.contexteUtilisateurService.hasContexte) {
          this.projetEspace = this.contexteUtilisateurService.Espace.code;
          this.codeProjet = this.contexteUtilisateurService.Projet.nom;
          this.projetDescription =
            this.contexteUtilisateurService.Projet.description;
          this.emailSimulatedUser = this.utilisateurApiService.currentUser
            .email_simulateur
            ? this.utilisateurApiService.currentUser.email
            : null;
          this.hasEmailSimulatedUser = !!this.emailSimulatedUser;
        }
      }
    );
  }

  openConfigurationChantier() {
    this.router.navigate(['/execution/configurationChantier']);
  }

  simulate() {
    this.utilisateurApiService
      .getSimulateUser(this.emailSimulatedUser, this.codeProjet)
      .subscribe((data) => {
        this.contexteUtilisateurService.ResetChantier();
        this.hasEmailSimulatedUser = true;
        setTimeout(this.safeReload, 500);
      });
  }

  exitModeSimulation() {
    this.utilisateurApiService
      .exitSimulateUser(this.codeProjet)
      .subscribe(() => {
        this.contexteUtilisateurService.ResetChantier();
        this.hasEmailSimulatedUser = false;
        this.emailSimulatedUser = null;
        setTimeout(this.safeReload, 500);
      });
  }

  safeReload() {
    if (document.readyState === 'complete') {
      window.location.reload();
    } else {
      setTimeout(this.safeReload, 500);
    }
  }
}
