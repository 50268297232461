import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LayoutService } from "src/app/core/services/app.layout.service";
import { ContexteUtilisateurService } from "src/app/core/services/contexte-utilisateur.service";
import { Router } from "@angular/router";
import { UtilisateurApiService } from "src/app/core/api-services/utilisateur-api.service";
import { DroitElementaireEnum } from "src/app/shared/enums/DroitElementaireEnum";

@Component({
  selector: "app-header",
  templateUrl: "./app.header.component.html",
  styleUrls: ["./app.header.component.scss"],
})
export class AppHeaderComponent implements OnInit {
  projetDescription: string;
  projetEspace: string;
  emailSimulatedUser: string;
  inModelSimulation: boolean;
  codeProjet: string;

  droitElementaireEnum = DroitElementaireEnum;
  hasSimulatedDroitElementaire: boolean = false;

  constructor(
    private contexteUtilisateurService: ContexteUtilisateurService,
    private utilisateurApiService: UtilisateurApiService,
    public layoutService: LayoutService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.contexteUtilisateurService.contexteUtilisateurSubject.subscribe(
      (_) => {
        if (this.contexteUtilisateurService.hasContexte) {
          this.projetEspace = `${this.contexteUtilisateurService.Chantier.nom} ${this.contexteUtilisateurService.Espace.nom}`;
          this.codeProjet = this.contexteUtilisateurService.Projet.nom;
          this.projetDescription =
            this.contexteUtilisateurService.Projet.description;
        } else {
          this.projetEspace = null;
          this.codeProjet = null;
          this.projetDescription = null;
        }

        this.inModelSimulation =
          this.utilisateurApiService.currentUser.in_model_simulation;
        if (this.inModelSimulation)
          this.emailSimulatedUser =
            this.utilisateurApiService.currentUser.email;
        else
          this.emailSimulatedUser =
            this.utilisateurApiService.currentUser.email_simulateur;
        this.hasSimulatedDroitElementaire =
          this.utilisateurApiService.hasDroitElementaires([
            DroitElementaireEnum.SIMULATION,
          ]) || this.inModelSimulation;
      }
    );
  }

  openConfigurationChantier() {
    this.router.navigate(["/execution/configurationChantier"]);
  }

  simulate() {
    this.codeProjet = this.contexteUtilisateurService.Projet.nom;
    this.utilisateurApiService
      .getSimulateUser(this.emailSimulatedUser, this.codeProjet)
      .subscribe((data) => {
        this.contexteUtilisateurService.ResetChantier();
        this.inModelSimulation = true;
        setTimeout(this.safeReload, 500);
      });
  }

  exitModeSimulation() {
    this.codeProjet = this.contexteUtilisateurService.Projet.nom;
    this.utilisateurApiService
      .exitSimulateUser(this.codeProjet)
      .subscribe(() => {
        this.contexteUtilisateurService.ResetChantier();
        this.inModelSimulation = false;
        this.emailSimulatedUser = null;
        setTimeout(this.safeReload, 500);
      });
  }

  safeReload() {
    if (document.readyState === "complete") {
      window.location.reload();
    } else {
      setTimeout(this.safeReload, 500);
    }
  }
}
